@mixin fadeIn {
	opacity: 0;
	animation: fade 0.5s ease-in 0.2s 1 normal forwards;
}

@keyframes fade {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}