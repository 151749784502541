
@use "assets/scss/global";
@use "assets/scss/core/variables/breakpoints" as *;
@use "assets/scss/core/variables/colors" as colors;
@use "assets/scss/core/variables/spacing" as *;

.layoutContainer {
  padding: 2rem;
  margin: auto 0;
  max-width: 800px;
  // display: flex;
  // align-items: center;

  @media(min-width: $screenMdMin){
    padding: 0rem 01rem;
  }
}

.link{
  &:hover svg{
    color: colors.$info;
  }
	a{
		display: flex;
		gap: 0.4rem;
		color: colors.$darkGray;
		font-size: 1rem;
		color: colors.$primary;
    text-transform: uppercase;
	}
	svg {
		color: colors.$primary;
	}
}