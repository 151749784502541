$sp-1: 0.25rem;
$sp-2: 0.5rem;
$sp-3: 0.75rem;
$sp-4: 1rem;
$sp-5: 1.5rem;
$sp-6: 1.75rem;
$sp-7: 2rem;
$sp-8: 2.5rem;
$sp-9: 3rem;
$sp-10: 3.5rem;
$sp-11: 4rem;
$sp-12: 4.5rem;
$sp-13: 5rem;

:export {
  sp-1: $sp-1;
  sp-2: $sp-2;
  sp-3: $sp-3;
  sp-4: $sp-4;
  sp-5: $sp-5;
  sp-6: $sp-6;
  sp-7: $sp-7;
  sp-8: $sp-8;
  sp-9: $sp-9;
  sp-10: $sp-10;
  sp-11: $sp-11;
  sp-12: $sp-12;
  sp-13: $sp-13;
}