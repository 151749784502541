@use "assets/scss/global";
@use "assets/scss/core/variables/colors" as colors;
@use "assets/scss/core/variables/breakpoints" as *;
@use "assets/scss/core/variables/boxshadows" as *;
// @use "assets/scss/core/variables/spacing" as *;
@use "assets/scss/core/animation" as *;

.headerContainer {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1rem;

	// @media(min-width: $screenSmMin){
	// 	justify-content: flex-end;
	// }
}

.drawerContainer {
	box-shadow: right-shadow-reduced(4);
}

.mainContent {
	flex-grow: 1;
	overflow: auto;
	padding-top: 0.5rem;
	// margin: $sp-2 $sp-2 0 $sp-2;

	// @media (min-width: $screenMdMin) {
	// 	margin: $sp-4 $sp-4 0 $sp-4;
	// }

	// the scroll to top button goes here when scrolled fully down
	&:after {
		content: "";
		display: block;
		height: 7rem;

		@media (min-width: $screenMdMin) {
			height: 6rem;
		}
	}
}

.footerContainer {
	display: flex;
	justify-content: space-evenly;
	flex: 1;
	align-items: flex-end;
	a {
		color: colors.$primary;
	}
}

.fadeIn {
	@include fadeIn;
}