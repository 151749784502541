@use "variables/fonts" as font;
@use "variables/colors" as colors;
@use "variables/spacing" as spacing;
@use "variables/borders" as borders;
@use "variables/fonts" as fonts;
@use "variables/boxshadows" as *;
@use "assets/scss/core/variables/breakpoints" as breakpoints;


/** STYLE **/
html {
  font-family: font.$family;
  font-size: 12px;

  @media(min-width: breakpoints.$screenSmMin){
    font-size: 14px;
	}
}

hr {
  border-bottom: borders.$primary-md;
  border-top: none;
}