$primary: #c75910;
$secondary: #e99156;
$warning: #d89c43;
$danger: #a35928;
$error: #af3f30;
$success: #4fa052;
$info: #328f9b;
$rose: #a14453;
$darkGray: #3a3a3a;
$gray: #c0c0c0;
$lightGray: #f6f9fa;

$black: #000;
$white: #fff;

$background_default_light: #ffffff;
$background_default_dark: rgb(32, 33, 36);

$highlight_light: #d9d9d9;
$highlight_dark: rgb(43, 45, 50);

:export {
    primary: $primary;
    secondary: $secondary;
    warning: $warning;
    danger: $danger;
    error: $error;
    success: $success;
    info: $info;
    rose: $rose;
    gray: $gray;
    lightGray: $lightGray;
    darkGray: $darkGray;
    black: $black;
    white: $white;
    background_default_light: $background_default_light;
    background_default_dark: $background_default_dark;
    highlight_light: $highlight_light;
    highlight_dark: $highlight_dark;
}
