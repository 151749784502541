@forward "assets/scss/global";
@use "assets/scss/core/variables/breakpoints" as *;
// @use "assets/scss/core/variables/spacing" as *;

.layoutContainer {
  // display: flex;
  // flex-direction: column;

  margin: 0px auto 0px auto;
  padding: 0.5rem;
  box-sizing: border-box;

  @media(min-width: $screenSmMin){
    width: calc(100vw - 240px );
  }
  @media(min-width: $screenMdMin){
    width: min(calc(100vw - 240px), 1200px);
  }
}

.layoutContainerContent {
  overflow: hidden;
}