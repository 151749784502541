@use "assets/scss/core/variables/borders" as borders;

.container{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    
    .dropZone {
        flex-grow: 1;
        margin: 0.2rem;
        padding: 1rem;
        border-radius: 0.3rem;
        border: borders.$dashed-md;
        border-color: rgba(0, 0, 0, 0);
        transition: all ease 0.2s;

        &.dragging{
            border: borders.$dashed-md;
            background-color: rgba(0, 0, 0, 0.1);
        }

        &.hot{
            border: borders.$primary-dashed-md;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    &.loading{
        z-index: 3;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 254, 254, 0.7);
    }
}
