@use "assets/scss/core/animation" as *;

.container {
    @include fadeIn;
    position: relative;
    border: solid 1px;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;    

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: auto;
    }
}
.squareContainer {
    height: 10rem;
    width: 10rem;
    min-width: 10rem;
    min-height: 10rem;
}
.fullWidth {
    height: 4rem;
    width: 100%;
    min-width: 4rem;
    min-height: 2rem;
}

.control {
    width: 40px;
    height: 40px;
    background-color: rgba(255,255,255,0.5) !important;
    border: solid 2px #000000;
    border-style: outset;
    margin: auto;

    &:disabled {
        background-color: rgba(0,0,0,0.5) !important;
    }
}