@use "assets/scss/core/variables/boxshadows" as *;
@use "assets/scss/core/variables/borders" as *;
@use "assets/scss/core/variables/colors" as colors;
@use "assets/scss/core/variables/breakpoints" as *;
@use "assets/scss/core/animation" as *;

.scrollToTop {
    z-index: 1000;
    position: fixed;
    bottom: 2rem;
    left: calc(50% - 2rem);
    justify-content: center;

    @media (min-width: $screenSmMin) {
        left: calc(50% + 120px - 2rem); // drawerWidth 240px
    }

    button {
        width: 4rem;
        height: 4rem;
        @include boxshadow(1);
        border-radius: $br-3;
        border: $secondary-sm;
        color: colors.$secondary;
        &:hover {
            color: colors.$primary;
            border: $primary-sm;
        }
    }
}

.fadeIn {
    @include fadeIn;
}
