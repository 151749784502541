@use "assets/scss/core/variables/breakpoints" as *;
@use "assets/scss/core/variables/spacing" as *;
@use "assets/scss/core/variables/colors" as colors;
@use "assets/scss/core/variables/boxshadows" as *;


.splashContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.leftImageContainer {
  flex-basis: 0px;
  transition: all 1s;

  img {
    width: 0%;
  }

  @media(min-width: $screenMdMin){
    flex-basis: 400px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    box-shadow: right-shadow(3);
    height: 100vH;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.rightInfoContainer{  
  flex-grow: 1;
  height: 100vH;
  overflow: scroll;
  display: flex;
  justify-content: center;
  @media(min-width: $screenMdMin){
    margin: auto $sp-1;
    // padding: $sp-9 0;
    overflow: scroll;
  } 
}

