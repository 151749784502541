@use "assets/scss/global";
@use "assets/scss/core/variables/colors" as colors;
@use "assets/scss/core/variables/boxshadows" as *;
@use "assets/scss/core/variables/spacing" as *;
@use "assets/scss/core/variables/borders" as borders;

.moduleAccordion{
	border-radius: 0 !important;
	box-shadow: none;

	&::before {
		display: none;
	}
	
	.moduleAccordionSummary{
		font-weight: bold;
		text-transform: uppercase;
		border-bottom: borders.$dark-sm;
	}

	.moduleAccordionDetails{
		display: flex;
		flex-direction: column;
		gap: $sp-3;
		padding: 0.7rem 0.5rem 0rem 0.5rem,
	}
}

.groupContainer {
	border-radius: borders.$br-3;
	padding: $sp-2 0;
	@include boxshadow(1);

	.menuButtonSingle:nth-child(1) {
		border-bottom: borders.$gray-sm;
		padding-bottom: $sp-2;		
	}
}

.menuButtonSingle{
	padding: 0.4rem 0px 0.4rem 1rem;
	a{
		display: flex;
		gap: 0.4rem;
		color: colors.$darkGray;
		// font-size: 1rem;
	}
	a[aria-disabled="true"] {
		cursor: default;
		color: colors.$lightGray;
	}
}