// @use "variables/fonts";
// @use "variables/colors.scss" as *;

/** RESET CSS **/
/*
  1. Use a more-intuitive box-sizing model.
  // 2. Remove default margin
*/
*
// ,*::before
// ,*::after 
{
  box-sizing: border-box;
  margin: 0;
}
/*
  
/*
  3. Allow percentage-based heights in the application
*/
// html,
// body {
//   // min-height: 100vh;
//   // height: 100%;

//   ::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
//     background: transparent;
//   }
// }
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
// body {
//   line-height: 1.5;
//   -webkit-font-smoothing: antialiased;
// }
/*
  6. Improve media defaults
*/
// img,
// picture,
// video,
// canvas,
// svg {
//   display: block;
//   max-width: 100%;
// }
/*
  7. Remove built-in form typography styles
*/
// input,
// button,
// textarea,
// select {
//   font: inherit;
// }
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  // margin: 0;
}

// /*
//   9. Create a root stacking context
// */
// #root,
// #__next {
//   isolation: isolate;
// }

/** STYLE **/
html {
  scroll-behavior: smooth;
}

a{
  // display: flex;
	// align-items: center;
  text-decoration: none;
}


:root, [data-amplify-theme], [data-amplify-authenticator]{
  --amplify-colors-font-primary: #000000;
  --amplify-colors-font-active: #000000;
  --amplify-colors-font-focus: #000000;
  --amplify-colors-font-hover: #000000;
  --amplify-colors-font-interactive: #000000;

  --amplify-colors-border-focus: #c75910;

  --amplify-components-button-primary-background-color: #c75910;
  --amplify-components-button-primary-hover-background-color:#e99156;
  --amplify-components-button-primary-focus-background-color:#c75910;
  --amplify-components-button-primary-active-background-color:#c75910;

  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-active-background-color: transparent;
}
