$shadowBlurMultiplier: 8px;

@mixin boxshadow($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 4 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth), left-shadow($depth), right-shadow($depth);  
  }
}

@function calcShadowOffset($depth){
  @return nth(3 1.2 2 5, $depth);
}
@function calcShadowReducedOffset($depth){
  @return nth(4 7 9 13, $depth);
}
@function calcShadowBlur($depth){
  @return nth(5 6 8 8, $depth);
}
@function calcShadowSpread($depth){
  @return nth(4 7 9 9, $depth);
}
@function calcShadowColorOpacity($depth){
  @return nth( .03 .24 .29 .23, $depth);
}
@function calcShadowReducedColorOpacity($depth){
  @return nth(.13 .15 .20 .19, $depth);
}

/// Computes a top-shadow.
@function top-shadow($depth) {
  $primary-offset: calcShadowOffset($depth) * -1px;
  $blur: calcShadowBlur($depth) * 1px;
  $color: rgba(black, calcShadowColorOpacity($depth));

  @return 0 $primary-offset $blur $color;
}
@function top-shadow-reduced($depth) {
  $primary-offset: calcShadowReducedOffset($depth) * -1px;
  $blur: calcShadowBlur($depth) * 1px;
  $spread: calcShadowSpread($depth) * -1px;
  $color: rgba(black, calcShadowReducedColorOpacity($depth));

  @return 0 $primary-offset $blur $spread $color;
}

/// Computes a bottom-shadow.
@function bottom-shadow($depth) {
  $primary-offset: calcShadowOffset($depth) * 1px;
  $blur: calcShadowBlur($depth) * 1px;
  $color: rgba(black, calcShadowColorOpacity($depth));

  @return 0 $primary-offset $blur $color;
}
@function bottom-shadow-reduced($depth) {
  $primary-offset: calcShadowReducedOffset($depth) * 1px;
  $blur: calcShadowBlur($depth) * 1px;
  $spread: calcShadowSpread($depth) * -1px;
  $color: rgba(black, calcShadowReducedColorOpacity($depth));

  @return 0 $primary-offset $blur $spread $color;
}

@function bottom-right-left-shadow($depth) {
  $primary-offset: calcShadowReducedOffset($depth) * 1px;
  $blur: calcShadowBlur($depth) * 1px;
  $spread: calcShadowSpread($depth) * 0.25px;
  $color: rgba(black, calcShadowReducedColorOpacity($depth));

  @return 0 $primary-offset $blur $spread $color;
}

/// Computes a left-shadow.
@function left-shadow($depth) {
  $primary-offset: calcShadowOffset($depth) * -1px;
  $blur: calcShadowBlur($depth) * 1px;
  $color: rgba(black, calcShadowColorOpacity($depth));

  @return $primary-offset 0 $blur $color;
}
@function left-shadow-reduced($depth) {
  $primary-offset: calcShadowReducedOffset($depth) * -1px;
  $blur: calcShadowBlur($depth) * 1px;
  $spread: calcShadowSpread($depth) * -1px;
  $color: rgba(black, calcShadowReducedColorOpacity($depth));

  @return $primary-offset 0 $blur $spread $color;
}

/// Computes a right-shadow.
@function right-shadow($depth) {
  $primary-offset: calcShadowOffset($depth) * 1px;
  $blur: calcShadowBlur($depth) * 1px;
  $color: rgba(black, calcShadowColorOpacity($depth));

  @return $primary-offset 0 $blur $color;
}
@function right-shadow-reduced($depth) {
  $primary-offset: calcShadowReducedOffset($depth) * 1px;
  $blur: calcShadowBlur($depth) * 1px;
  $spread: calcShadowSpread($depth) * -1px;
  $color: rgba(black, calcShadowReducedColorOpacity($depth));

  @return $primary-offset 0 $blur $spread $color;
}