@use "core/variables/breakpoints" as *;
@use "core/variables/spacing" as *;
@use "core/variables/borders" as *;

:global .MuiLoadingButton-root{
    padding: $sp-4 $sp-6;
    border-radius: $br-2;
    margin-left: auto;
    margin-right: auto;
  }

:global .MuiTextField-root, :global .MuiAutocomplete-root{
  width: 100%;
}