$family: "Helvetica", "Arial", sans-serif;

$H1FontSize: 1.4rem;
$H2FontSize: $H1FontSize - 0.2rem;
$H3FontSize: $H2FontSize - 0.2rem;
$H4FontSize: $H3FontSize - 0.1rem;
$H5FontSize: $H4FontSize - 0.1rem;
$H6FontSize: $H5FontSize;

:export {
    fontFamily: $family;
    H1FontSize: $H1FontSize;
    H2FontSize: $H2FontSize;
    H3FontSize: $H3FontSize;
    H4FontSize: $H4FontSize;
    H5FontSize: $H5FontSize;
    H6FontSize: $H6FontSize;
}
