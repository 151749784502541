@use "assets/scss/global";
@use "assets/scss/core/variables/colors" as colors;
@use "assets/scss/core/variables/boxshadows" as *;
@use "assets/scss/core/variables/spacing" as *;
@use "assets/scss/core/variables/borders" as borders;
@use "assets/scss/core/variables/breakpoints" as breakpoints;
@use "assets/scss/core/animation" as *;

.imageContainer {
	@media (min-width: breakpoints.$screenSmMin) {
		@include fadeIn;
		width: 240px;
		height: 240px;
		overflow: hidden;
		img {
			object-fit: cover;
			@include boxshadow(1);
		}
	}
}

.name {
	font-size: 0.9rem;
	font-weight: 600;
	padding: 0.8rem 0;
}

.profileButton {
	padding: 0.2rem 0px 0.2rem 0rem;
	a{
		display: flex;
		gap: 0.6rem;
		&:hover { 
			color: colors.$info;
		}
		p {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		svg {
			font-size: 1rem;
			padding: 0px !important;
			margin: 0px !important;
		}
	}
}