@use "assets/scss/core/variables/borders" as *;
@use "assets/scss/core/variables/colors" as *;
@use "assets/scss/core/animation" as *;

.imageContainer {
    position: relative;
    margin: auto;
    border: $dark-sm;
    border-radius: $br-1;
    overflow: hidden;

    img {
        object-fit: cover;
        transition: opacity 0.3s ease-in
    }
}