@use "assets/scss/core/variables/breakpoints" as *;

.actionButton {
    height: 3.5rem;
    width: 3.5rem;
    border: solid 1px;
    border-radius: 3rem;
    // margin-top: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    @media (min-width: $screenSmMin) {
        border: none;
        margin-left: 0.2rem;
        height: 3rem;
        width: 3rem;
    }

    svg {
        height: 100%;
        width: 100%;
    }
}