@use "./colors" as colors;

$gray-sm: 1px solid colors.$lightGray;
$gray-md: 2px solid colors.$lightGray;
$gray-lg: 3px solid colors.$lightGray;

$primary-sm: 1px solid colors.$primary;
$primary-md: 2px solid colors.$primary;
$primary-lg: 3px solid colors.$primary;

$secondary-sm: 1px solid colors.$secondary;
$secondary-md: 2px solid colors.$secondary;
$secondary-lg: 3px solid colors.$secondary;

$secondary-dashed-sm: 1px dashed colors.$secondary;
$secondary-dashed-md: 2px dashed colors.$secondary;
$secondary-dashed-lg: 3px dashed colors.$secondary;

$dark-sm: 1px solid rgba(0, 0, 0, 0.25);
$dark-md: 2px solid rgba(0, 0, 0, 0.25);
$dark-lg: 3px solid rgba(0, 0, 0, 0.25);

$light-sm: 1px solid rgba(255,255,255, 0.25);
$light-md: 2px solid rgba(255,255,255, 0.25);
$light-lg: 3px solid rgba(255,255,255, 0.25);

$dashed-sm: 1px dashed rgba(0, 0, 0, 0.25);
$dashed-md: 2px dashed rgba(0, 0, 0, 0.25);
$dashed-lg: 3px dashed rgba(0, 0, 0, 0.25);

$primary-dashed-sm: 1px dashed colors.$primary;
$primary-dashed-md: 2px dashed colors.$primary;
$primary-dashed-lg: 3px dashed colors.$primary;

$br-1: 0.25rem;
$br-2: 0.5rem;
$br-3: 0.75rem;
$br-4: 1rem;
$br-5: 1.5rem;
$br-6: 1.75rem;
$br-7: 2rem;

:export {
  gray-sm: $gray-sm;
  gray-md: $gray-md;
  gray-lg: $gray-lg;

  primary-sm: $primary-sm;
  primary-md: $primary-md;
  primary-lg: $primary-lg;

  dark-sm: $dark-sm;
  dark-md: $dark-md;
  dark-lg: $dark-lg;

  br-1: $br-1;
  br-2: $br-2;
  br-3: $br-3;
  br-4: $br-4;
  br-5: $br-5;
  br-6: $br-6;
  br-7: $br-7;
}
