@use "assets/scss/global";
@use "assets/scss/core/variables/colors" as colors;
@use "assets/scss/core/variables/boxshadows" as *;
@use "assets/scss/core/variables/spacing" as *;
@use "assets/scss/core/variables/borders" as borders;


.container{
	display: flex;
	flex-direction: column;
	gap: $sp-3;
	padding: 0.7rem 0.5rem 0rem 0.5rem;

	// sepperate the settings from the applications
	// >:last-child{
	// 	margin-top: 1rem;
	// }
}

.menuButtonContainer{
	// margin: 0.4rem;
	border: solid 1px white;
	border-radius: borders.$br-3;
	@include boxshadow(1);

	a{
		border-radius: borders.$br-3;
		flex-grow: 1;
		padding: 1rem;
		display: flex;
		align-items: center;
		gap: 0.4rem;
		font-size: 1rem;
		text-transform: uppercase;
		cursor: pointer !important;
	}

	&.active{
		a{
			color: colors.$primary;
			font-weight: bold;
		}
	}
}